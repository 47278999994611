$(function(){
    initMobileMenu();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.uno-mfp-popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 1);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();

    function initSearchBar() {
        $('#search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    $('#menu nav li:has(ul)').doubleTapToGo();

    var backTop = $("#back_to_top");
    var backTop2 = $(".totop_image");
    var backTop3 = $("#back_to_top2");

    backTop.click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    backTop2.click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    backTop3.click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    //sticky footer
    adjustFooter();

    $("#footer-content.home .site-map").on("click", function() {
        $(".top-footer").slideToggle();
        $("#newsletter-wrapper").toggleClass("showing");
        // $(".site-map").toggleClass("showing");
        $(".sitemap").fadeOut();
        $("html, body").animate({scrollTop: $(".top-footer").offset().top}, "800");
    })

    $("#footer-content.inner .site-map").on("click", function() {
        $(".top-footer").slideToggle();
        $(".bottom-footer #back_to_top2 .totop_text").toggleClass("white");
        // $(".site-map").toggleClass("showing");
        $(".sitemap").fadeOut();
        $("html, body").animate({scrollTop: $(".top-footer").offset().top}, "800");
    })
});

$(document).ready(function() {
    var mobileMenu = $('#mobile-menu').data('mmenu');
    var mobileIcon = $("#menuBtn a i");

    mobileMenu.bind('opened', function () {
        mobileIcon.removeClass("fa-bars");
        mobileIcon.addClass("fa-close");
    });
    mobileMenu.bind('closed', function () {
        mobileIcon.removeClass("fa-close");
        mobileIcon.addClass("fa-bars");
    });
    $('.close-btn').on('click',function(){
        mobileMenu.close();
    })
});

function adjustFooter() {
    if ($(window).height() < 900) {
        $("#landing_page_footer").css("position", "relative");
        $("#landing_page_footer").css("margin-top", "40px");
    } else {
        $("#landing_page_footer").css("position", "absolute");
        $("#landing_page_footer").css("margin-top", "0");
    }
}

function initMobileMenu() {
    var menu = $('#menu nav').clone(),
        mobileMenu = $('#mobile-menu');
    mobileMenu.append(menu.html()).mmenu({
        extensions: ["fullscreen"],
        navbars: [
            {
                position: "top",
                content: [
                    "prev",
                    "title",
                    "close"
                ],
                height: 1
            }
        ],
        offCanvas: {
            position: "left"
        }
    });
}

$(window).load(function() {
    imgToBG($('.bgimg'), '.imgbg');
});

function imgToBG(ele, _imgClass){
    if(ele.length > 0){
        ele.each(function(){
            var _this = $(this), img = _this.find(_imgClass).eq(0) || _this.find('img').eq(0);
            _this.css({'background': 'url("'+img.attr('src')+'")', 'background-repeat':'no-repeat'});
            img.hide();
        });
    }
    $.fn.matchHeight._update()
}
